import CheckIcon from '@mui/icons-material/Check'

import ErrorIcon from '@mui/icons-material/PriorityHigh'
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp'

export type IconStatus = 'ACTIVE' | 'COMPLIANT' | 'INACTIVE'
export const getFraudIcon = (isCompliant: boolean, statusCode: IconStatus) => {
  if (statusCode !== 'ACTIVE') {
    return { icon: HorizontalRuleSharpIcon, status: 'INACTIVE' }
  }
  if (isCompliant) {
    return { icon: CheckIcon, status: 'COMPLIANT' }
  }
  return { icon: ErrorIcon, status: '' }
}
