import { Typography, Box } from '@mui/material'
import { compliantStyle } from '../../../../../components-new/data-display/Table/Table.styles'
import { getFraudIcon, IconStatus } from 'utils/getFraudIcon'

type FraundIndicatorCellProps = {
  row: any
}

const FraundIndicatorCell = ({ row }: FraundIndicatorCellProps) => {
  const isCompliant = !row?.original?.fraudIndicators

  const compliance = getFraudIcon(isCompliant, row.original.statusCode as IconStatus)
  const Icon = compliance.icon
  const DisplayContent = () => (
    <Typography sx={{ ...compliantStyle(compliance.status as IconStatus) }}>
      <Icon sx={{ fontSize: '2.4rem', padding: '0.4rem' }} />
    </Typography>
  )

  return (
    <Box display="flex" data-cy="body-cell-fraud-indicators">
      <DisplayContent />
    </Box>
  )
}

export default FraundIndicatorCell
