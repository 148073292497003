import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'
import { IconStatus } from 'utils/getFraudIcon'

export const getScoreStyle = (score: number) => {
  let color = '#FFC52F'
  if (score <= 3) color = '#E73434'
  if (score >= 7) color = '#1FA745'
  return {
    borderRadius: '0.5rem',
    color: 'white',
    textAlign: 'center',
    width: theme.spacing(5),
    fontWeight: 600,
    backgroundColor: color,
    margin: 'auto',
  }
}

const iconColors = {
  ACTIVE: '#7CD094',
  COMPLIANT: '#7CD094',
  INACTIVE: '#878E95',
}
export const getColorStyle = (status: keyof typeof iconColors) => {
  const color = '#FC7B7B'

  return {
    padding: '0 8px',
    borderRadius: '20px',
    fontSize: '1.6rem',
    backgroundColor: iconColors[status] || color,
    margin: 'auto',
  }
}

export const getBadgeColor = (status: string) => {
  let borderColor
  let backgroundColor
  switch (status) {
    // theme styling not working for numbered keys, happy for suggestions
    case 'approved':
      borderColor = 'success.main'
      backgroundColor = '#E8F5E9'
      break
    case 'reject':
      borderColor = 'error.dark'
      backgroundColor = '#FFEAEE'
      break
    case 'manual_check':
      borderColor = 'secondary.dark'
      backgroundColor = '#FFF3E2'
      break
    case 'no_policy_set':
      borderColor = '#878E95'
      backgroundColor = '#F1F3F5'
      break
  }

  return {
    padding: '0 8px',
    borderRadius: '20px',
    fontSize: '1.6rem',
    height: theme.spacing(5),
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    borderWidth: 1,
    color: borderColor,
    margin: 'auto',
  }
}

export const compliantStyle = (status: IconStatus) => {
  return {
    ...getColorStyle(status),
    lineHeight: 0,
    padding: 0,
    color: theme.palette.common.white,
  }
}

export const getHeaderCellBorder = (i: number, length: number) => {
  return i > 0 && i < length - 1 ? '1px solid #d5d5d6' : 'none'
}
const table: { [key: string]: SxProps } = {
  container: {
    display: 'flex',
    overflow: 'auto',
  },
  header: {
    height: '60px',
    fontWeight: 600,
    display: 'flex',
    paddingY: '0.8rem',
    backgroundColor: theme.palette.grey['100'],

    '& > .container': {
      paddingX: '1rem',
    },
    alignItems: 'center',
    '&:hover': {
      '& .draggable-box': {
        visibility: 'visible',
        cursor: 'grab',
      },
    },
  },
  tableRow: {
    backgroundColor: 'grey.100',
  },
  tableCell: {
    padding: 0,
    '.MuiStack-root': {
      border: 'none',
    },
  },
  headerCell: {
    padding: 0,
    paddingLeft: 1,
    border: 'none',
  },
  columnArrangement: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  dragIndicator: {
    visibility: 'hidden',
    position: 'relative',
    top: '2px',
  },
  cell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sortingArrow: {
    color: '#909294',
    padding: '1px 2px',
  },
  greyField: {
    margin: 1,
    paddingX: 1,
    background: theme.palette.grey[100],
    borderColor: theme.palette.grey[100],
    borderRadius: '0.5rem',
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: theme.palette.grey[100],
      },
    },
    '& fieldset': {
      borderColor: theme.palette.grey[100],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },
  },
}

export const classes = {
  table,
}
